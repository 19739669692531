import React, { useState } from 'react';
import { Card, Button, Image, Figure, FigureImage, FigureCaption } from 'react-bootstrap';
import "../css/cards.css";
import OverlayText from './OverlayText';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getFlagLink, getScheme } from '../utils/Utils';
import { LuClipboardCopy } from "react-icons/lu";

const SatelliteCardImage = ({
    sat,
}) => {
    const [showImage, setShowImage] = useState(true);

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert(`Link Copied:\n ${text}`);
        } catch (error) {
            alert("Failed to Copy Link.");
            console.error('Failed to copy!', error);
        }
    }

    const hideImage = () => {
        setShowImage(false);
    }

    const isExternalURL = (url) => {
        if (url === "/static/media/sat_placeholder.5ec9548dae6a86c83e3c.jpg") {
            return false;
        }
        return true;
    }

    return (
        <>
            <Card
                style={{
                    backgroundColor: "#181b1f",
                    color: "white",
                    borderRadius: "0",
                    border: "0",
                }}
            >

                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                >
                    {(sat?.getImages(sat?.Data)) &&
                        (isExternalURL(sat?.getImages(sat?.Data)[0])
                            ? getScheme(sat?.Data, ["Image"]).map((images) => (
                                <Figure className="mb-0" key={images.Value}>
                                    <FigureImage
                                        className="mb-0"
                                        style={{
                                            width: "auto",
                                            maxHeight: "89vh"
                                        }}
                                        src={images.Value}
                                        alt={sat?.Name}
                                    />

                                    <>
                                        <FigureCaption className='pb-0 mt-2'>
                                            <OverlayText text={images.Source} maxLength={40} />
                                            <Button
                                                className='btn-copy'
                                                title='Copy Link'
                                                style={{ paddingTop: '0', paddingBottom: '0', marginLeft: '5px', marginRight: 'auto' }}
                                                onClick={() => handleCopy(images.Source)}>
                                                <LuClipboardCopy />
                                            </Button>
                                        </FigureCaption>

                                    </>
                                </Figure>
                            ))
                            : sat?.getImages(sat?.Data).map((url) => (
                                <Figure className="mb-0" key={url}>
                                    <FigureImage
                                        className="mb-0"
                                        src={url}
                                        alt={sat?.Name}
                                    />
                                </Figure>
                            ))
                        )
                    }

                </Carousel>

                {showImage && (
                    <Image
                        style={{
                            width: "50px",
                            height: "50px",
                            position: 'absolute',
                            zindex: 9999,
                            top: 0,
                            left: 5
                        }}
                        alt={sat?.CountryId}
                        src={getFlagLink(sat)}
                        onError={hideImage}
                    />
                )}

            </Card>
        </>
    );
};

export default SatelliteCardImage;
