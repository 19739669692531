import React, {useState, useEffect} from 'react';
import { Button, Row, Card } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { timeDifference, isLoggedIn, getScheme } from '../utils/Utils';
import { useUser } from "../contexts/user";
import ModalAddPoi from './ModalAddPoi';
import ModalEditPoi from './ModalEditPoi';
import '../css/poi.css';
import OverlayElement from './OverlayElement';
//icons
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { FiEdit } from 'react-icons/fi';

const Poi = ({ sat }) => {
    const {username} = useUser();
    const [showEditPoi, setShowEditPoi] = useState(false);
    const [sortedPois, setSortedPois] = useState([]);
    const [showAddPoi, setShowAddPoi] = useState(false);
    const [pois, setPois] = useState([]);
    const [poi, setPoi] = useState({});

    useEffect(()=>{
        if(sat?.Data){
            setPois(getScheme(sat.Data, ["poi"])[0].Value === "N/A" ? [] : getScheme(sat.Data, ["poi"]));
        }
    },[sat, sat?.Data, setPois]);

    useEffect(()=>{
        if(Array.isArray(pois)){
            const sorted = pois.sort((a,b)=>new Date(b.Timestamp) - new Date(a.Timestamp));
            setSortedPois(sorted);
        }
    }, [pois]);

    const [showTitle, setShowTitle] = useState("**No events to show")

    const editData = (poi) => {
        setPoi(poi);
        setShowEditPoi(!showEditPoi);
    }

    useEffect(()=>{
        if(sortedPois.length>0){
                setShowTitle(`${sortedPois.length} event(s)`);
        }
    }, [sortedPois])

    const getSource = (scheme) => {
        if(!scheme.Source) {
            return scheme.Value !== "N/A"
            ? <sup style={{color:"red"}}>
                    <OverlayElement 
                        text={" [Source]"} 
                        element={<div>No Source Provided</div>}
                        placement="left"/>
                </sup>
            : <></>
        }
        return  <sup className="style-source">
                    <OverlayElement 
                        text={" [Source]"} 
                        element={<Link className="style-link" to={scheme.Source}>{scheme.Source}</Link>} 
                        placement="left"/>
                </sup>;
    }

    const handleOnDelete = (delId) => {
        setPois(ps => ps.filter(p => p.Id !== delId))
        setPoi({});
    };

    const [expandedStates, setExpandedStates] = useState({});

    const toggleExpand = (id) => {
        setExpandedStates(prev => ({
            ...prev,
            [id]: !prev[id] // Toggle the expanded state for the specific poi
        }));
    };

  return (
    isLoggedIn(username) ?
        <>
            <ModalAddPoi
                show={showAddPoi}
                setShow={setShowAddPoi}
                sat={sat}
            />
            <Card className='mt-3'>
            <Card.Title style={{textAlign: "center", margin:"1rem", color:"#ffc107"}}>
                <p>Point of Interest Timeline</p>
                <h5 style={{color:"white"}}>{showTitle}</h5>
            </Card.Title>
            <Card.Body>
            {sortedPois.length > 0
            && (<>
            <div style={{textAlign: "center", margin:"0",color: "#ffc107"}}><h5>Newest</h5></div>
            {sortedPois.map((poi) => (
                <Timeline 
                    key={Math.random().toString()}
                    className="mb-0">
                        <TimelineItem
                            style={{paddingTop:'0px'}}>
                            <TimelineOppositeContent >
                                {timeDifference(poi.Timestamp)}
                                <br/>
                                ({poi.Timestamp}Z)
                            </TimelineOppositeContent>
                            <TimelineSeparator>

                            <TimelineConnector />

                            <div className="edit-box" onClick={() => editData(poi)}><FiEdit transform="translate(0 -6)" cursor="pointer" /></div>

                            <TimelineConnector sx={{ height: '50%' }} />

                        </TimelineSeparator>
                        <TimelineContent>
                            {expandedStates[poi.Id] ? poi.Value : `${poi.Value.substring(0, 20)}${poi.Value.length > 20 ? '...' : ''}`}
                            {poi.Value.length > 20 && (
                                <Button onClick={() => toggleExpand(poi.Id)} variant="link" style={{ textDecoration: 'none' }}>
                                    {expandedStates[poi.Id] ? '▲' : '▼'}
                                </Button>
                            )}
                            {getSource(poi)}
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            ))}
                          <div style={{ textAlign: "center", margin: "0", color: "#ffc107" }}><h5>Oldest</h5></div>
                      </>
            )}
            <ModalEditPoi
                key={poi.Id}
                show={showEditPoi}
                setShow={setShowEditPoi}
                poi={poi}
                onDelete={handleOnDelete}
            />
            <br></br>
            
            <Row className="text-center">
                <br></br>
                <Button
                    style={{
                        maxWidth:"150px",
                        marginLeft:"auto",
                        marginRight: "auto"
                    }}
                    variant="warning"
                    onClick={()=>setShowAddPoi(!showAddPoi)}
                >
                    <LiaMapMarkerAltSolid /> Add POI
                </Button>

            </Row> 
            </Card.Body>
        </Card>
        </>
    : 
        <div
            className="text-center pt-4"
            style={{color:"red"}}
        >
            Must be logged in to view the Point of Interest timeline.
        </div>
  );
};

export default Poi;
