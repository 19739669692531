import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Row, Col, Button, Container, Form, InputGroup, Image} from "react-bootstrap";
import { useUser } from "../contexts/user";
//mui
import { Grid } from '@mui/material';
//components
import ModalEditData from "../components/ModalEditDataV2.0";
import ModalAddSatellite from "../components/ModalAddSatellite";
import SatelliteCard from '../components/SatelliteCard';
import SearchModal from '../components/SearchModal';
import Loading from "../components/Loading";
//utils
import { isLoggedIn } from "../utils/Utils";
import { debouncedSearch } from '../utils/SearchUtils';
//image
import ProbeLogoPNG from "../assets/images/ProbeLogoPNG.png";
//icons
import { LuInfo, LuFilter, LuList, LuX, LuArrowUpRight, LuArrowDownRight, LuPlus } from "react-icons/lu";
import { IoIosSearch } from "react-icons/io";
import _ from 'lodash';

const Home = () => {
    const { username, satellites } = useUser();
    
    // Update state declarations to use batched values
    const [loggedIn, setLoggedIn] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filteredSats, setFilteredSats] = useState([]);
    const [showSatModal, setShowSatModal] = useState(false);
    const [hrrChecked, setHrrChecked] = useState(false);
    const [selectedSat, setSelectedSat] = useState(null);
    const [showAddSatModal, setShowAddSatModal] = useState(false);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const handleOpenQueryModal = () => setShowQueryModal(true);
    const handleCloseQueryModal = () => setShowQueryModal(false);
    const [parameter, setParameter] = useState('--');
    const [operator, setOperator] = useState('=');
    let [value, setValue] = useState('');
    const [visibleCount, setVisibleCount] = useState(9);
    const [isDescending, setIsDescending] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    // Create the base scroll handler function
    const handleScroll = useCallback(() => {
        const buffer = 50;
        const scrolledToBottom = 
            window.innerHeight + document.documentElement.scrollTop >= 
            document.documentElement.offsetHeight - buffer;
        
        if (scrolledToBottom && visibleCount < filteredSats.length && !isLoadingMore) {
            setIsLoadingMore(true);
            setVisibleCount(prevCount => prevCount + 9);
            setIsLoadingMore(false);
        }
    }, [visibleCount, filteredSats.length, isLoadingMore]);

    // Create throttled version of the handler
    const throttledScrollHandler = useMemo(
        () => _.throttle(handleScroll, 150),
        [handleScroll]
    );

    // Attach scroll listener with throttled handler
    useEffect(() => {
        window.addEventListener('scroll', throttledScrollHandler, { passive: true });
        return () => {
            throttledScrollHandler.cancel();
            window.removeEventListener('scroll', throttledScrollHandler);
        };
    }, [throttledScrollHandler]);

    useEffect(() => {
        setLoggedIn(isLoggedIn(username));
    }, [username, satellites]);

    useEffect(()=>{
        setSelectedSat(satellites[selectedSat?.SatNo]);
    }, [satellites, selectedSat]);
    
    const handleHrrToggle = () => {
        const newHrrChecked = !hrrChecked;
        setHrrChecked(newHrrChecked);
        debouncedSearch(searchValue, satellites, setFilteredSats, filters, newHrrChecked);
    };

    // Memoize the search parameters
    const searchParams = useMemo(() => ({
        searchValue,
        satellites,
        filters,
        hrrChecked
    }), [searchValue, satellites, filters, hrrChecked]);

    // Memoize the search callback
    const handleSearchChange = useCallback((event) => {
        const newSearchValue = event.target.value;
        setSearchValue(newSearchValue);
        
        debouncedSearch(
            newSearchValue,
            searchParams.satellites,
            setFilteredSats,
            searchParams.filters,
            searchParams.hrrChecked
        );
    }, [searchParams]);

    const addFilter = () => {
        if (parameter === "LaunchDate"){
            value = value + " 00:00:00"
        }
        const newFilter = { 
            parameter, 
            operator, 
            value: ['<', '>', '<=', '>='].includes(operator) ? parseFloat(value) : value 
        };
        const updatedFilters = [...filters, newFilter];
        setFilters(updatedFilters);
        setParameter('--');
        setOperator('=');
        setValue('');

        // Use debouncedSearch to apply both filters and search
        debouncedSearch(searchValue, satellites, setFilteredSats, updatedFilters, hrrChecked);
        setVisibleCount(9);
    };

    const removeFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
        
        // Use debouncedSearch to apply both filters and search
        debouncedSearch(searchValue, satellites, setFilteredSats, updatedFilters, hrrChecked);
        setVisibleCount(9);
    };

    const sortedSatsOrder = () => { //default: ascending
        const orderedSats = filteredSats.map((sat) => (sat));
        if (isDescending){
            orderedSats.reverse();
        }
        return orderedSats;
    }

    const isAscendDescend = () =>{
        return (isDescending
            ? <><LuArrowDownRight className="icon-lg" /> Descending</>
            : <><LuArrowUpRight className="icon-lg" /> Ascending</>);
    }

    // Add an effect to initialize the filtered satellites
    useEffect(() => {
        if (Object.keys(satellites).length > 0) {
            const allSats = Object.values(satellites);
            allSats.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(allSats);
        }
    }, [satellites]);  // Only run when satellites data changes

    return (
        <>
            <ModalEditData 
                show={showSatModal}
                setShow={setShowSatModal}
                showFooter={true}
                selectedSat={selectedSat}
            />
            <Container style={{ paddingTop: "25px" }}>

            <div style={{ backgroundSize: 'cover', color: '#fff', textAlign: 'center', padding: '30px 0' }}>
                <Container>
                    <Row>
                        <Col>
                            <Image 
                                width="400px" 
                                src={ProbeLogoPNG} 
                                alt="Probe Logo" 
                                fluid 
                                loading="eager"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{paddingTop:"1rem"}}>
                            <p>Open Source repository for global satellite data.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

                <Row>
                    <Col sm="10">
                        <h4>Satellites ({filteredSats.length})</h4>
                    </Col>
                </Row>
                <br/>

                {/* SEARCH */}
                <Row className="mb-1">
                    <Col>
                    <Form.Group controlId="search" className="border border-top-0 rounded-3">
                        <InputGroup>
                            <InputGroup.Text 
                                className="bg-warning border-warning"
                                style={{ padding: ".6rem .8rem" }}
                            >
                                <IoIosSearch style={{fontSize:"1.25rem", color: "black"}}/>
                            </InputGroup.Text>
                            
                            <Button
                                variant="outline-secondary"
                                title="Search Filters"
                                onClick={handleOpenQueryModal}
                            >
                                <LuFilter style={{fontSize:"1rem"}}/><LuList style={{fontSize:"1.25rem"}}/>
                            </Button>
                            
                            <Form.Control
                                required
                                type="text"
                                className="form-control-lg"
                                name="search"
                                value={searchValue}
                                placeholder="Search (NoradId, Name, Data etc)..."
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                        
                        
                        {/* Applied Filters */}
                        {filters.length > 0 && (
                            <span>
                                <p style={{margin:".5rem"}} >Applied Filters:</p>
                                <ul style={{ paddingLeft: '0', listStyleType: 'none', display: 'flex', flexWrap: 'wrap', gap: '5px', margin:".5rem" }}> {/* Reduced gap */}
                                    {filters.map((filter, index) => (
                                        <li key={index} style={{ display: 'flex', alignItems: 'center'}}> {/* Reduced margin-bottom */}
                                            <span className="bg-secondary"
                                                style={{ 
                                                marginRight: '.25rem', 
                                                backgroundColor: '#4D5656', 
                                                padding: '5px 10px', 
                                                borderRadius: '5px'
                                            }}>
                                                {filter.parameter} {filter.operator} "{filter.value}" 
                                                <button className="btn btn-secondary" 
                                                onClick={() => removeFilter(index)}
                                                style={{padding:"0px",marginLeft:"4px",marginBottom:"4px"}}>
                                                    <LuX fontSize={"1.25rem"}/>
                                                </button>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </span>
                        )}  
                        
                    </Form.Group>
                    </Col>
                    <p style={{ paddingTop:"1rem"}}>
                        <LuInfo/> This is publicly available information and was not provided through Intel channels.
                    </p>
                </Row>
                
                <Row>
                <Col style={{ display: 'flex', alignItems: 'normal', gap:"10px"}}>
                {isLoggedIn(username) && (
                    <>
                        <Button
                            type="checkbox"
                            className="mb-4"
                            variant={hrrChecked ? "success" : "light"}
                            onClick={handleHrrToggle}
                        >
                            HRR Only
                        </Button>
                    </>
                )}
                <Button
                    className="mb-4"
                    variant="light"
                    onClick={() => setIsDescending(!isDescending)}
                >
                    {isAscendDescend()}
                </Button>

                {isLoggedIn(username) && (
                    <Button
                    className="mb-4"
                    variant="warning"
                    onClick={() => setShowAddSatModal(true)}
                    style={{marginLeft:"auto"}}
                    >
                        <div 
                        style={{verticalAlign:"center"}}>
                            <LuPlus/> Add Satellite
                        </div>
                    </Button>
                )}
                </Col>
                </Row>

                <SearchModal 
                    show={showQueryModal}
                    handleClose={handleCloseQueryModal}
                    parameter={parameter}
                    setParameter={setParameter}
                    operator={operator}
                    setOperator={setOperator}
                    value={value}
                    setValue={setValue}
                    addFilter={addFilter}
                    filters={filters}
                    removeFilter={removeFilter}
                />
                
                {Object.keys(satellites).length === 0 && (<Loading/>)}
                <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                    {sortedSatsOrder().slice(0, visibleCount).map((sat) => (
                        <Grid item key={sat?.Id ?? Math.random().toString()} xs={12} sm={12} md={6} lg={4}>
                            <SatelliteCard
                                key={sat?.Id ?? Math.random().toString()}
                                setShowSatModal={setShowSatModal} 
                                sat={sat}
                                loggedIn={loggedIn}
                                setSelectedSat={setSelectedSat}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <ModalAddSatellite show={showAddSatModal} setShow={setShowAddSatModal} />
            
            {isLoadingMore && (
                <div className="text-center my-4">
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Home;
