import _ from 'lodash';

export const debouncedSearch = _.debounce((searchValue, satellites, setFilteredSats, filters, hrrChecked) => {
    const memoKey = `${searchValue}-${JSON.stringify(filters)}-${hrrChecked}`;
    
    if (searchCache.has(memoKey)) {
        setFilteredSats(searchCache.get(memoKey));
        return;
    }

    let results = Object.values(satellites);
    
    if (hrrChecked) {
        results = results.filter(s => s.HrrRank !== null);
    }
    
    if (filters.length > 0) {
        results = results.filter(satellite => {
            return filters.every(({ parameter, operator, value }) => {
                let satelliteValue = satellite[parameter];
                if (parameter === 'HrrRank' && satelliteValue !== null) {
                    satelliteValue = parseFloat(satelliteValue);
                    value = parseFloat(value);
                }
                switch (operator) {
                    case '=': return satelliteValue === value;
                    case '!=': return satelliteValue !== value;
                    case '<': return satelliteValue < value;
                    case '>': return satelliteValue > value;
                    case '<=': return satelliteValue <= value;
                    case '>=': return satelliteValue >= value;
                    default: return true;
                }
            });
        });
    }

    if (searchValue) {
        const searchTerms = searchValue.toLowerCase().split(',').map(term => term.trim()).filter(Boolean);
        results = results.filter(satellite => 
            searchTerms.some(term => 
                ['Name', 'SatNo', 'CountryId', 'ObjectType', 'Regime', 'HrrRank'].some(field => {
                    const fieldValue = satellite[field]?.toString().toLowerCase() || '';
                    return fieldValue.includes(term);
                })
            )
        );
    }
    
    results.sort((a, b) => a.SatNo - b.SatNo);
    
    searchCache.set(memoKey, results);
    
    if (searchCache.size > 100) {
        const firstKey = searchCache.keys().next().value;
        searchCache.delete(firstKey);
    }
    
    setFilteredSats(results);
}, 300);

const initializeSearchCache = () => {
    try {
        const cachedData = sessionStorage.getItem('search_cache');
        if (cachedData) {
            return new Map(JSON.parse(cachedData));
        }
    } catch (error) {
        console.warn('Failed to restore search cache:', error);
    }
    return new Map();
};

export const searchCache = initializeSearchCache();

export const clearSearchCache = () => {
    searchCache.clear();
    sessionStorage.removeItem('search_cache');
};