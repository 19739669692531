export const REGIMES = {
    1: "Undetermined",
    2: "Leo",
    4: "Heo",
    8: "GeoInclined",
    16: "Meo",
    32: "Molniya",
    64: "Sso",
    128: "Polar",
    256: "GeoStationary",
    512: "Graveyard",
};

export const SPACE_COCKPIT_BASE_URL = "https://spacecockpit.saberastro.com/";

export const Wezs = {
    "Jammer": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Laser": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Lidar": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Net": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Armature": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Projectile": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Radar": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Sprayer": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Telescope": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },

    "Microwave": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },
    "Explosive": {
          "Custom":   {"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""},
    },
}
