import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from "../contexts/user";
import NotFound from '../components/NotFound';
import { Helmet } from 'react-helmet';
import Loading from '../components/Loading'; // Assuming you have a loading component
import { timeDifference, isGeo, isLoggedIn, getScheme} from "../utils/Utils";
import OverlayText from '../components/OverlayText';

import "../css/cards.css";

import { Col, Row, Card } from 'react-bootstrap';
import SatelliteCardImage from '../components/SatCardHorizontal';

const DossierMin = () => {
    const { username, satellites } = useUser();
    const { id } = useParams();
    const [notFound, setNotFound] = useState(false);
    const [sat, setSat] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [maxCols, setMaxCols] = useState(3);
    
    useEffect(() => { 
        if (satellites[id]) {
            setSat({ ...satellites[id] });
            setNotFound(false);
            setIsLoading(false); // Data is available, stop loading
        } else if (Object.keys(satellites).length > 0) {
            setNotFound(true);
            setIsLoading(false); // Stop loading even if satellite not found
        }
    }, [id, satellites]);

    useEffect(() => {
        setMaxCols(isLoggedIn(username) && sat?.HrrRank ? 3 : 4);
    }, [username, sat?.HrrRank]);

    const getName = (sat) => {
        const scheme = getScheme(sat.Data, ["name"]);
        if (scheme?.[0].Value === "N/A") return "";
        return "Alias: " + scheme.map(s => s.Value).join(",");
    }

    document.body.style.backgroundColor = "#181b1f";
    
    if (isLoading) {
        return <Loading />; // Show a loading indicator until data is ready
    }

    if (notFound) {
        return <NotFound id={id} />;
    }

    return (
        <>
            {sat && (
                <div style={{backgroundColor: "#181b1f",}}>
                    <Helmet>
                        <title>({sat.SatNo}) - Min</title>
                        <meta name="satellite-name" content={`Satellite: ${sat.Name}`} />
                        <meta property="description" content={sat.getDescription(sat.Data)} />
                    </Helmet>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Card style={{
                            width: "65%",
                            height:"100%",   
                            padding:"15px",
                            borderRadius: "0",
                            border: "0",
                            }}>

                        <Card.Title className="card-text bold" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ color: '#ffc107' }}>{sat?.SatNo} - {sat?.Name}</span>
                            <button
                                onClick={() => window.open(`https://probe.saberastro.com/dossier/${sat?.SatNo}`, '_blank')}
                                style={{
                                backgroundColor: 'transparent',
                                color: '#ffc107',
                                border: '2px solid #ffc107',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                marginLeft: '10px'
                                }}
                                title="Visit full dossier page"
                            >
                                Go to Dossier
                            </button>
                        </Card.Title>

                            <div style={{ fontSize: "12px", marginBottom: "10px" }}>
                                {getName(sat)}
                            </div>
                            <br></br>
                            <div className="card-text">

                                <Row className="bold">
                                    <Col xs={4}>Last Seen</Col>
                                    {isGeo(sat) && (
                                        <>
                                            <Col xs={4}>Lon (°E)</Col>
                                            <Col xs={4}>Drift (°E/d)</Col>
                                        </>
                                    )}
                                    {!isGeo(sat) && (
                                        <>
                                            <Col xs={4}>Inc (°)</Col>
                                            <Col xs={4}>Raan (°)</Col>
                                        </>
                                    )}
                                </Row>
                                <Row>
                                    <Col xs={4}>{timeDifference(sat?.Epoch)}</Col>
                                    {isGeo(sat) && (
                                        <>
                                            <Col xs={4}>{sat?.Longitude}</Col>
                                            <Col xs={4}>{sat?.LonDriftDegreesPerDay}</Col>
                                        </>
                                    )}
                                    {!isGeo(sat) && (
                                        <>
                                            <Col xs={4}>{sat?.Inclination}</Col>
                                            <Col xs={4}>{sat?.Raan}</Col>
                                        </>
                                    )}
                                </Row>

                                <br />
                                <Row className="bold">
                                    {isLoggedIn(username) && sat?.HrrRank && (
                                        <Col xs={2}>Rank</Col>
                                    )}
                                    <Col xs={maxCols}>Regime</Col>
                                    <Col xs={maxCols}>Type</Col>
                                    <Col xs={maxCols}>Status</Col>
                                </Row>
                                <Row>
                                    {isLoggedIn(username) && sat?.HrrRank && (
                                        <Col xs={2}>{sat?.HrrRank}</Col>
                                    )}
                                    <Col xs={maxCols}>
                                        {sat?.Regime.toLowerCase()}
                                    </Col>
                                    <Col xs={maxCols}>
                                        {sat?.ObjectType ? sat?.ObjectType.toLowerCase() : ""}
                                    </Col>
                                    <Col xs={isLoggedIn(username) && sat?.HrrRank ? 3 : 4}>
                                        {sat?.getStatus(sat?.Data)}
                                    </Col>
                                </Row>

                            </div>
                            <br />
                            <b>Summary</b>
                            <div style={{ marginBottom: "20px", width: "100%" }}>
                                <OverlayText
                                    text={sat?.getDescription(sat?.Data)}
                                    maxLength={300}
                                    style={{ width: "100%" }}
                                />
                            </div>

                        </Card>
                        <Col lg={true} className="d-flex justify-content-center">
                            <SatelliteCardImage
                                sat={sat}
                            />
                        </Col>
                        
                    </div>
                </div>
            )}
        </>
    );
};

export default DossierMin;
